// Import React
import { useState } from 'react';
// Import packages
import { useNavigate } from 'react-router-dom';
// Import app common components and types
import { Formik, Form, FormikHelpers } from 'formik';
import { add } from 'date-fns';
import * as Yup from 'yup';

import PlaneType from '../../../types/PlaneType';
import Itinerary from './Itinerary/Itinerary';
import Container from '../../wrappers/Container/Container';
import { AirportId } from '../../../types/AirportLocation';
import { CREW_TYPE_OPTIONS, CrewType } from '../../../types/CrewType';
import { PrimaryButton } from '../../atoms/Button/Button';
// Import hooks and utilities
import { ROUTES } from '../../../routes/routerConfig';
// Import CSS
import 'react-datepicker/dist/react-datepicker.css';
import createNewTrip from '../../../API/Trips/createNewTrip';
import { useAircraft } from '../../../hooks/useAircraft';
import { Airplane } from '../../../types/Airplane';
import { useAirports } from '../../../hooks/AirportsProvider';
import FormikField from '../../atoms/Form/FormikField';
import FormikDropdown, {
  FormikDropdownProps,
} from '../../atoms/Form/FormikDropdown';
import Loader from '../../molecules/Loader';
import Debug from '../../atoms/Form/Debug';
import FormikTextArea from '../../atoms/Form/FormikTextArea';
import { useToast } from '../../../hooks/ToastProvider';
import Link from '../../atoms/Link';
import Alert from '../../atoms/Alert/Alert';
import { usePlaneTypes } from '../../../hooks/PlaneTypesProvider';
import { TripLeg, createTripLeg } from '../../../types/TripLeg';
import objectToString from '../../../utilities/objectToString';
import Trip from '../../../types/Trip';

function AirplaneDropdown({
  airplanes,
  name,
  label,
  placeholder,
}: {
  airplanes: Airplane[];
  name: FormikDropdownProps<any>['name'];
  label: FormikDropdownProps<any>['label'];
  placeholder?: FormikDropdownProps<any>['placeholder'];
}) {
  const { getCraftNameById } = usePlaneTypes();
  const airplaneOptions = airplanes
    // .filter((plane) => plane.isValid)
    .map((airplane) => {
      return {
        label: `${
          airplane?.identification?.tailNumber || 'Unknown'
        } (${getCraftNameById(airplane.model)})`,
        value: airplane.id!,
      };
    });
  return (
    <FormikDropdown
      options={airplaneOptions}
      name={name}
      label={label}
      placeholder={placeholder}
      optional={false}
    />
  );
}

function OperatorDropdown({
  airplane,
  name,
  label,
  placeholder,
}: {
  airplane: Airplane | null;
  name: FormikDropdownProps<any>['name'];
  label: FormikDropdownProps<any>['label'];
  placeholder?: FormikDropdownProps<any>['placeholder'];
}) {
  const operatorOptions: FormikDropdownProps<any>['options'] =
    airplane?.identification
      ? airplane.identification.ownerOperator
          .filter((op) => op.type === 'operator' || op.type === 'both')
          .map((op) => ({
            label: op.name,
            value: op.id!,
          }))
      : [];

  return (
    <FormikDropdown
      options={operatorOptions}
      name={name}
      label={label}
      placeholder={placeholder}
      optional={false}
    />
  );
}

function CrewTypeDropdown({
  name,
  label,
  placeholder,
}: {
  name: FormikDropdownProps<any>['name'];
  label: FormikDropdownProps<any>['label'];
  placeholder: FormikDropdownProps<any>['placeholder'];
}) {
  // Filter out Instructor for now
  const crewTypeOptions = Object.entries(CREW_TYPE_OPTIONS).map((ct) => ({
    value: ct[0],
    label: ct[1],
  }));

  return (
    <FormikDropdown
      options={crewTypeOptions}
      name={name}
      label={label}
      placeholder={placeholder}
      optional={false}
    />
  );
}

type TripProps = {
  fromAirportId: AirportId | null;
  toAirportId: AirportId | null;
  departureTime: Date | null;
  returnDate: Date | null;
  tripType: string;
  crewType: number | null;
  notes: string;
  pax: number;
  planeType: PlaneType | null;
  airplaneId: Airplane['id'] | null;
  operator: number | null;
};

function CreateTripForm({ className }: { className: string }) {
  const navigate = useNavigate();

  const { addToast } = useToast();

  const { getAirportFromId } = useAirports();

  const { airplanes, loading } = useAircraft();

  // We need to know specifically which plane is selected,
  // so here I'm using planeId instead of planeType
  // onSubmit we'll find the type by matching id to the correct airplane
  // from the airplanes array
  const initialValues: TripProps = {
    fromAirportId: null,
    toAirportId: null,
    departureTime: new Date(),
    returnDate: null,
    tripType: '',
    crewType: null,
    notes: '',
    pax: 0,
    planeType: null,
    airplaneId: airplanes.length ? airplanes[0].id : null,
    operator: null,
  };

  const [itinerary, setItinerary] = useState<TripLeg[]>([
    createTripLeg({
      index: 0,
      departureTime: new Date().toISOString(),
      arrivalTime: new Date().toISOString(),
      pax: 0,
    }),
  ]);

  const handleSubmit = async (
    values: TripProps,
    formikHelpers: FormikHelpers<TripProps>
  ) => {
    const plane = airplanes.find((ap) => ap.id === values.airplaneId)!;
    const name = `${getAirportFromId(itinerary[0].toLocation!)?.city} Trip`;
    const endDate =
      itinerary.length > 1
        ? itinerary[itinerary.length - 1].departureTime
        : add(new Date(itinerary[0].departureTime), { hours: 2 });
    const endLocation =
      itinerary.length > 1
        ? itinerary[itinerary.length - 1].toLocation
        : itinerary[0].toLocation;

    formikHelpers.setFieldValue(
      'departureTime',
      new Date(itinerary[0].departureTime)
    );
    formikHelpers.setFieldValue('returnDate', new Date(endDate));
    formikHelpers.setFieldValue('purpose', values.tripType);
    formikHelpers.setFieldValue('fromAirportId', itinerary[0].fromLocation);
    formikHelpers.setFieldValue('toAirportId', endLocation);

    formikHelpers.setSubmitting(true);

    // set departureDate field error if departureDate is less than 12 hours from now
    const departureDate = new Date(itinerary[0].departureTime);

    if (departureDate < add(Date.now(), { hours: 12 })) {
      addToast('Departure Time must be at least 12 hours from now', 'error');

      formikHelpers.setSubmitting(false);
      return;
    }

    try {
      const newTripResponse = await createNewTrip({
        name,
        notes: values.notes,
        pax: itinerary[0].pax,
        departureDate: new Date(itinerary[0].departureTime),
        returnDate: new Date(endDate),
        fromAirportId: `${itinerary[0].fromLocation}`,
        toAirportId: `${endLocation}`,
        planeTypeId: `${plane.model}`,
        purpose: values.tripType,
        tailNumber: plane.identification.tailNumber,
        aircraft: values.airplaneId,
        operator: values.operator,
        crewType:
          Number(values.crewType) === 3
            ? [1, 2]
            : (values.crewType as CrewType),
        legs: itinerary,
      });
      formikHelpers.setSubmitting(false);

      if (Array.isArray(newTripResponse.trip)) {
        addToast(
          <span>Trip created for each needed crew member.</span>,
          'success'
        );
        navigate(`${ROUTES.TRIPS}/`);
      }

      if (newTripResponse.statusCode === 200) {
        addToast(<span>Trip created.</span>, 'success');
        navigate(`${ROUTES.TRIPS}/`);
      }
    } catch (error) {
      console.log(error);
      addToast(<span>{(error as Error).message}</span>, 'error');
    }
  };

  if (loading) return <Loader caption="Loading aircraft" />;

  return (
    <div className="lg:max-w-6xl">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          // fromAirportId: Yup.string().required('From airport is required'),
          // toAirportId: Yup.string().required('To airport is required'),
          departureTime: Yup.date().required('Departure time is required'),
          crewType: Yup.mixed().required('Crew type is required'),
          notes: Yup.string().required('Notes are required'),
          pax: Yup.number().required('Passenger count is required'),
          // planeType: Yup.object().required('Plane type is required'),
          airplaneId: Yup.string().required('Aircraft is required'),
          operator: Yup.string().required('Operator is required'),
        })}
      >
        {(formik) => (
          <Form>
            <div className="flex flex-col gap-0">
              {airplanes.filter((plane) => plane.isValid).length > 0 ? (
                <>
                  <AirplaneDropdown
                    airplanes={airplanes}
                    name="airplaneId"
                    label="Aircraft"
                    placeholder="Select an Aircraft"
                  />
                  {formik.values.airplaneId ? (
                    <>
                      <OperatorDropdown
                        airplane={
                          airplanes.find(
                            (ap) => ap.id === formik.values.airplaneId
                          )!
                        }
                        name="operator"
                        label="Operator/Lessee"
                        placeholder="Select an Operator or Lessee"
                      />
                      <CrewTypeDropdown
                        name="crewType"
                        label="Crew Type Needed"
                        placeholder="Select a Crew Type"
                      />
                      <Itinerary
                        className="col-span-2"
                        itinerary={itinerary}
                        setItinerary={setItinerary}
                      />
                      <FormikField
                        label="Notes"
                        name="notes"
                        component={FormikTextArea}
                      />
                      <div>
                        <PrimaryButton
                          type="submit"
                          isLoading={formik.isSubmitting}
                        >
                          Create Trip
                        </PrimaryButton>
                      </div>
                    </>
                  ) : null}
                </>
              ) : (
                <Alert
                  status="error"
                  message={
                    <>
                      You do not have any valid aircraft. Please{' '}
                      <Link to={ROUTES.ADD_AIRCRAFT}>add an aircraft</Link> or
                      fill out the remaining information on
                      <Link to={ROUTES.MY_AIRCRAFT}>
                        {' '}
                        your existing aircraft
                      </Link>{' '}
                      before creating a trip.
                    </>
                  }
                />
              )}

              {/* <Heading level={3} className="col-span-2">
                Crew Details
              </Heading>
              <div>
                <Label htmlFor="plane" required>
                  Required Crew
                </Label>
                <Select
                  className="mt-s max-w-lg"
                  id="plane"
                  options={CrewTypeOptions}
                  onChange={updateCrewType}
                />
              </div>
              <div>
                <Label htmlFor="plane">Send to Favorite Pilot?</Label>
                <Select
                  className="mt-s max-w-lg"
                  id="favoritePilot"
                  options={dummyPilots}
                  onChange={updateFavoritePilot}
                />
              </div>
              <div className="col-span-2">
                <Label htmlFor="notes" required>
                  Notes
                </Label>
                <TextArea
                  id="notes"
                  value={inputs.notes}
                  onChange={handleChange}
                  className="max-w-lg"
                />
              </div>
              <Itinerary
                className="col-span-2"
                itinerary={itinerary}
                setItinerary={setItinerary}
              />
              <div
                className={`flex gap-4 ${
                  windowWidth > query.tablet ? 'flex-row' : 'flex-col'
                }`}
              >
                <SecondaryButton isLoading={isLoading} onClick={onSubmit}>
                  Submit
                </SecondaryButton>
                <StateButton status="error" onClick={goToTrips}>
                  Cancel
                </StateButton>
              </div> */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateTripForm;
