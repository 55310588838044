// Import shared app components and types
import Conversation from '../../../types/Conversation';
import { Expense } from '../../../types/Expense';
import Link from '../../atoms/Link';
import Rule from '../../atoms/Rule';
import Card from '../../molecules/Card/Card';
import Heading from '../../typography/Heading';

function GrandTotalEstimateCard({
  isPilot = false,
  isQuoted = false,
  cost,
  expenses,
  className = '',
}: {
  isPilot?: boolean;
  isQuoted?: boolean;
  cost: Conversation['cost'];
  expenses: Expense[];
  className?: string;
}) {
  const { estimation } = cost;
  const {
    flightDays,
    flyingCost,
    travelDays,
    travelCost,
    pricePerDay: pilotFlyingRate,
    dayDiem: dayPerDiem,
    diem: perDiemTotal,
    platformFee: serviceFee,
    totalCharge: totalCost,
  } = estimation;

  const pilotTravelDayRate = estimation.travelDays
    ? estimation.travelCost / estimation.travelDays
    : 0;
  const tripDuration = flightDays + travelDays;
  const pilotTotal: number = totalCost - serviceFee;

  const estimatedExpenseCost = expenses
    .filter((e) => e.isQuoted)
    .reduce((acc, cur) => {
      // eslint-disable-next-line no-param-reassign
      acc += cur.cost;
      return acc;
    }, 0 as number);

  const finalExpenseCost = expenses
    .filter((e) => !e.isQuoted)
    .reduce((acc, cur) => {
      // eslint-disable-next-line no-param-reassign
      acc += cur.cost;
      return acc;
    }, 0 as number);

  const groupedExpenses = expenses.reduce<Record<string, Expense>>(
    (acc, expense) => {
      if (!acc[expense.title]) {
        acc[expense.title] = {
          title: expense.title,
          cost: 0,
          isQuoted: expense.isQuoted || false,
          receipt: expense.receipt || '',
        };
      }
      acc[expense.title].cost += expense.cost;
      return acc;
    },
    {}
  );

  const combinedExpenses: Expense[] = Object.values(groupedExpenses);

  let expense = 0;
  let tripCost = 0;
  let preTotal = 0;
  if (isQuoted) {
    expense = estimatedExpenseCost;
  } else {
    expense = finalExpenseCost;
  }

  if (isPilot) {
    tripCost = pilotTotal;
  } else {
    tripCost = totalCost;
  }

  if (!isQuoted) preTotal = tripCost - expense;
  else preTotal = tripCost;

  return (
    <Card responsive noPadding className={className}>
      <div className="py-2 background bg-primary rounded-t-lg">
        <Heading
          level={4}
          className="uppercase text-center text-white font-normal py-2"
        >
          {isQuoted ? 'Quote Estimate' : 'Invoice'}
        </Heading>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2 p-4">
          <div className="flex">
            <span>
              Flying Day-Rate ({flightDays} days, ${pilotFlyingRate.toFixed(2)}
              /day):
            </span>
            <span className="ml-auto font-semibold">
              ${flyingCost.toFixed(2)}
            </span>
          </div>
          <div className="flex">
            <span>
              Travel Day-Rate ({travelDays} days
              {pilotTravelDayRate
                ? `, $${pilotTravelDayRate.toFixed(2)}/day)`
                : ')'}
              :
            </span>
            <span className="ml-auto font-semibold">
              ${travelCost.toFixed(2)}
            </span>
          </div>
          <div className="flex">
            <span>
              Per-Diem ({tripDuration} days, ${dayPerDiem.toFixed(2)}/day):
            </span>
            <span className="ml-auto font-semibold">
              ${perDiemTotal.toFixed(2)}
            </span>
          </div>
          {!isPilot && (
            <div className="flex">
              <span>Service Fee:</span>
              <span className="ml-auto font-semibold mr-2">
                ${serviceFee.toFixed(2)}
              </span>
              <Link href="https://helpcenter.flyingcompany.com/en/help/articles/1092605-flying-company-service-fees">
                <i className="fa-solid fa-circle-question" />
              </Link>
            </div>
          )}
        </div>
        <div className="py-2 background bg-primary">
          <Heading
            level={4}
            className="uppercase text-center text-white font-normal py-2"
          >
            {isQuoted ? 'Pre-trip total' : 'Pre-expense Total'}: $
            {preTotal.toFixed(2)}
          </Heading>
        </div>
        <div className="flex flex-col gap-2 p-4">
          {!isPilot && isQuoted && (
            <div className="flex">
              <span className="italic text-xs">
                <i className="fas fa-plus-circle" />{' '}
                <span>
                  The pre-trip total is the estimated cost for pilot services,
                  travel days, per diem, and base service fee, subject to
                  mission changes.
                </span>
              </span>
            </div>
          )}
          <Heading level={4} className="underline font-semibold">
            {isQuoted ? 'Estimated Expenses' : 'Expenses'}:
          </Heading>
          {combinedExpenses.length
            ? combinedExpenses.map((expense) => (
                <div className="flex" key={expense.title}>
                  <span>{expense.title}:</span>
                  <span className="ml-auto font-semibold border-b border-black border-dotted">
                    ${expense.cost.toFixed(2)}
                  </span>
                </div>
              ))
            : 'Pending'}
          {combinedExpenses.length && (
            <>
              <Rule className="my-2" />
              <div className="flex">
                <span className="font-bold">
                  {isQuoted
                    ? 'Estimated total expenses:'
                    : 'Final expense total:'}
                </span>
                <span className="ml-auto font-semibold border-b border-black border-dotted">
                  $
                  {isQuoted
                    ? estimatedExpenseCost.toFixed(2)
                    : finalExpenseCost.toFixed(2)}
                </span>
              </div>
            </>
          )}
          {isQuoted && !isPilot && (
            <div className="flex">
              <span className="italic text-xs">
                <i className="fas fa-plus-circle" />{' '}
                <span>
                  Actual expenses, such as hotel, ground travel, air travel, and
                  any additional costs, will be added after the trip and may
                  vary from the estimates provided. These expenses will incur an
                  additional service fee.
                </span>
              </span>
            </div>
          )}
        </div>
        <div className="py-2 background bg-primary rounded-b-lg">
          <Heading
            level={4}
            className="uppercase text-center text-white font-normal py-2"
          >
            {isQuoted ? 'Estimated Total' : 'Final Total'}: $
            {isPilot
              ? (pilotTotal + estimatedExpenseCost).toFixed(2)
              : (totalCost + estimatedExpenseCost).toFixed(2)}
          </Heading>
        </div>
      </div>
    </Card>
  );
}

export default GrandTotalEstimateCard;
