/* eslint-disable no-param-reassign */
import React from 'react';
import { ErrorMessage as FormikError, FieldArray, FormikProps } from 'formik';
import FormikInput from '../../../atoms/Form/FormikInput';
import FormikField from '../../../atoms/Form/FormikField';
import Card from '../../../molecules/Card/Card';
import CardTitle from '../../../molecules/Card/CardTitle';
import { SecondaryButton, StateButton } from '../../../atoms/Button/Button';
import i18n from '../../../../strings/i18n';
import { FuelCard } from '../../../../types/AirplaneAttributes/AirplaneAttributesOnHired';
import FormikCheckbox from '../../../atoms/Form/FormikCheckbox';
import ErrorMessage from '../../../molecules/ErrorMessage';
import { Airplane } from '../../../../types/Airplane';

// https://formik.org/docs/api/fieldarray#fieldarray-validation-gotchas
export default function AircraftFuelCards() {
  const setPreferred = (form: any, id: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    form.values.operatingProcedures?.fuelCards?.forEach((card) => {
      if (card.id !== id) {
        card.isPreferred = false;
      } else {
        card.isPreferred = false;
      }
    });
  };

  return (
    <Card responsive>
      <CardTitle>Fuel Cards</CardTitle>
      <p>Please mark one as preferred.</p>
      <FieldArray name="operatingProcedures.fuelCards">
        {({ form, insert, remove, push }) => (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>Card Name</th>
                  <th>Default</th>
                  <th>{i18n({ common: 'delete' })}</th>
                </tr>
              </thead>
              <tbody>
                {form.values.operatingProcedures?.fuelCards?.map(
                  ({ id, cardName, isPreferred }: FuelCard, index: number) => (
                    <tr key={id || index}>
                      <td>
                        <FormikField
                          label="Name"
                          name={`operatingProcedures.fuelCards.${index}.cardName`}
                          placeholder="Visa"
                          component={FormikInput}
                        />
                      </td>
                      <td>
                        <FormikField
                          label="This is my preferred fuel card"
                          name={`operatingProcedures.fuelCards.${index}.isPreferred`}
                          inline
                          component={FormikCheckbox}
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          onClick={() => setPreferred(form, id)}
                        />
                      </td>
                      <td>
                        <StateButton
                          status="error"
                          onClick={() => {
                            form.setFieldValue(
                              'operatingProcedures.deletedFuelCards',
                              [
                                ...form.values.operatingProcedures
                                  .deletedFuelCards,
                                form.values.operatingProcedures.fuelCards[index]
                                  .id,
                              ]
                            );

                            remove(index);
                          }}
                        >
                          X
                        </StateButton>
                      </td>
                    </tr>
                  )
                )}
                <tr>
                  <td colSpan={3}>
                    <SecondaryButton
                      onClick={() =>
                        push({
                          cardName: '',
                          isPreferred: false,
                        })
                      }
                    >
                      {i18n({ newAircraft: 'addFuelCard' })}
                    </SecondaryButton>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* @see https://formik.org/docs/api/fieldarray#fieldarray-validation-gotchas */}
            {typeof (form as FormikProps<Airplane>)?.errors?.operatingProcedures
              ?.fuelCards === 'string' ? (
              <FormikError
                component={ErrorMessage}
                name="operatingProcedures.fuelCards"
              />
            ) : null}
          </>
        )}
      </FieldArray>
    </Card>
  );
}
